// import "bootstrap";

// document.body.style.color = "red";
document.addEventListener('DOMContentLoaded', function(e) {
  let open_popup_btns = [...document.querySelectorAll('.open_popup')]
  
  open_popup_btns.map(btn => btn.addEventListener('click', function(e) {
    e.preventDefault()
    if(this.dataset && this.dataset.select) {
      let selects = [...document.querySelectorAll('.__select__input')]
      selects.map(select => {
        select.removeAttribute('checked')
      })
      document.querySelector(this.dataset.select).setAttribute('checked', true)
      const selectSingle_title = selectSingle.querySelector('.__select__title');
      selectSingle_title.textContent = document.querySelector(this.dataset.select).value;
    }
    document.querySelector('#formSend').classList.add('active')
  }))
  
  const selectSingle = document.querySelector('.__select');
  const selectSingle_title = selectSingle.querySelector('.__select__title');
  const selectSingle_labels = selectSingle.querySelectorAll('.__select__label');
  
  // Toggle menu
  selectSingle_title.addEventListener('click', () => {
    if ('active' === selectSingle.getAttribute('data-state')) {
      selectSingle.setAttribute('data-state', '');
    } else {
      selectSingle.setAttribute('data-state', 'active');
    }
  });
  
  // Close when click to option
  for (let i = 0; i < selectSingle_labels.length; i++) {
    selectSingle_labels[i].addEventListener('click', (evt) => {
      selectSingle_title.textContent = evt.target.textContent;
      selectSingle.setAttribute('data-state', '');
    });
  }
  
  let closeBtns = [...document.querySelectorAll('.close_this_modal')]
  closeBtns.map(btn => {
    btn.addEventListener('click', function(e) {
      document.querySelector(this.dataset.form)?.classList.remove('active')
    })
  })
  
  
  let forms = [...document.querySelectorAll('.popup_wrapper')]
  forms.map(item => item.addEventListener('click', function(e) {
    e.stopPropagation()
    this.classList.remove('active')
  }))
  let formsChild = [...document.querySelectorAll('.popup')]
  formsChild.map(item => item.addEventListener('click', function(e) {
    e.stopPropagation()
  }))
  
  document.addEventListener('keyup', function(e) {
    if(e.code == "Escape") {
      forms.map(form => form.classList.remove('active'))
    }
  })
  
  // отправка формы обратной связи
  let sendMailBtn = [...document.querySelectorAll('.sendFormBtn')]
  sendMailBtn.map(btn => {
    btn.addEventListener('click', function(e) {
      e.preventDefault()
      let form = document.querySelector(this.dataset.form)
      let data = new FormData(form);
      fetch(`https://formspree.io/f/mdoyyjqv`, {
        method: `POST`,
        body: data,
        headers: {
            'Accept': 'application/json'
        }
      }).then(response => {
          document.querySelector('.popupConfirm').classList.add('active')
          document.querySelector('#formSend').classList.remove('active')
          form.reset()
      }).catch(error => {
        alert('Неизвестная ошибка')
      });
    })
  })
  
  
  // Маска номера телефона
  window.addEventListener("DOMContentLoaded", function() {
  function setCursorPosition(pos, elem) {
      elem.focus();
      if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
      else if (elem.createTextRange) {
          var range = elem.createTextRange();
          range.collapse(true);
          range.moveEnd("character", pos);
          range.moveStart("character", pos);
          range.select()
      }
  }
  
  function mask(event) {
      var matrix = "+7 (___) ___ __ __",
          i = 0,
          def = matrix.replace(/\D/g, ""),
          val = this.value.replace(/\D/g, "");
      if (def.length >= val.length) val = def;
      this.value = matrix.replace(/./g, function(a) {
          return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
      });
      if (event.type == "blur") {
          if (this.value.length == 2) this.value = ""
      } else setCursorPosition(this.value.length, this)
  };
  
  
      var inputs = [...document.querySelectorAll(".user-phone-mask")];
      inputs.map(input => {
        input.addEventListener("input", mask, false);
        input.addEventListener("focus", mask, false);
        input.addEventListener("blur", mask, false);
      })
  });
  
  document.querySelector('.closeConfirm').addEventListener('click', function() {
      document.querySelector('.popupConfirm').classList.remove('active')
  })
})
